import React from "react"
import { Location } from '@reach/router'
import Hero from "../../components/hero-cannabis"
import Content from "../../components/content-cannabis"
import styled from "styled-components"

import "../../assets/index.css"
import Layout from "../../layouts/layout-contactus"
import { Link } from "gatsby"


const SiteFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: #E5F5FB;
    justify-content: center;
    margin: auto;
    display:block;
`;

const SubFooterContent = styled.div `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 0 80px;

    svg {
        padding-right: 25px;
    }

    div {
        max-width: 707px;
    }

    p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
    }

    @media only screen and (max-width: 1140px) {
        max-width: 600px;
        margin:auto;
        padding-bottom: 92px;

        svg {
            width: 100%;
            padding-bottom: 10px;
            padding-right: 0;
        }

        div, p, h2 {
            text-align: center;
            max-width: 375px;
        }

        p {
            max-width: 80%;
            margin: auto;
        }

        h2 {
            margin-bottom: 40px;
        }

    }
`;

const ContactUsButton = styled(Link)`
    background: var(--buttons);
    border: 1px solid var(--buttons);
    border-radius: 2px;
    width: 260px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    color: var(--white);
    float: right;
    margin-top: -15px;
    cursor: pointer;
    margin-bottom: 0;
    padding: 0;
    height: 60px;
    line-height: 60px;
    margin-left: 30px;

    &:focus, &:hover {
        outline: none;
        background: #23C0F5;
        border-color: #23C0F5;
    }

    &:active {
        background: #16A0CF;
        border-color: #16A0CF;
        }
    }

    @media only screen and (max-width: 1140px) {
        margin-top: 60px;
        margin-left: 0;
    }
`

export default () => (
    <Layout title="Cannabis Essentials | Ladd Partners">
        <Hero/>

        <Location>
            {({ location }) => {
                return  <><Content location={{ location }} /></>
            }}
        </Location>

        <SiteFooter>
            <SubFooterContent>
                <div>
                    <h2 className="margin-bottom-10">Request a Demo of <br/>Cannabis Essentials ERP</h2>
                    <p>We will work with you to arrange a demonstration tailored to your organizations needs.</p>
                </div>
                <ContactUsButton to="/solutions/cannabisessentials-demo">Request a Demo</ContactUsButton>
            </SubFooterContent>
        </SiteFooter>
    </Layout>
)
